import React from "react";
import ReactDOM from "react-dom/client";
import { IconButton, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, TextField, styled, CircularProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, Table, TableRow, TableHead, Card, CardContent } from '@mui/material';
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { useState, useEffect } from 'react';
import { createClient } from '@deepgram/sdk';
import Vapi from '@vapi-ai/web';
import axios from 'axios';
import io from 'socket.io-client';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SyncIcon from '@mui/icons-material/Sync';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GoogleIcon from '@mui/icons-material/Google';
import ChatIcon from '@mui/icons-material/Chat';
import StopIcon from '@mui/icons-material/Stop';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArticleIcon from '@mui/icons-material/Article';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import Avatar from '@mui/material/Avatar';
import ReactGA from "react-ga4";
const _ = require('lodash');

ReactGA.initialize([
  {
    trackingId: "G-KEJZXKN80Q",
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });

// TODO: move to process.env.DEEPGRAM_API_KEY
const deepgram = createClient('55dba888de36fd0a971cd6619beeb4ac9235c725');
var firstSilenceTimestamp = 0;
const vapi = new Vapi('c6a21d89-0ffd-45b2-9ad4-28a1fd2708f8')
var callId;
var socket;

var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
var env = String(process.env.REACT_APP_ENV)

if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5000'
  wsEndpoint = 'ws://localhost:5000'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

var stopInvoked = false;
const emptyInterviewObject = {
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {},
  scorecard: [],
  scoring_pending: false
}
const StateToCueMap = {
  'none': 'Get started by clicking on the "Start Interview" button above.',
  "FirstResponse": "Let Steve know you can hear him ok. He's a bit hard of hearing, so you may have to tell him twice.",
  "ProblemAffirmation": "Tell Steve you want to proceed if you feel comfortable with the problem.",
  "DrawOnWhiteboard": 'Complete the diagram on the whiteboard to solve the given problem. You can talk to Steve if you get stuck. Click the "get whiteboard feedback" button to get whiteboard feedback.'
}

// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
var globalInterviewType = 'retail'
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam) {
  globalInterviewType = interviewTypeQueryParam
}

const App = () => {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [interviewStarted, setInterviewStarted] = useState(false); 
    const [waitlistIsOpen, setWaitlistIsOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    // const [callId, setCallId] = useState(0)
    const [interview, setInterview] = useState(emptyInterviewObject);
    const [openHelp, setOpenHelp] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [loadingDiagramFeedback, setLoadingDiagramFeedback] = useState(false);
    const [whiteboardState, setWhiteboardState] = useState({});
    const [micEnabled, setMicEnabled] = useState(false);
    const [visualState, setVisualState] = useState('');
    const [firstMobileScreenDone, setFirstMobileScreenDone] = useState(false);
    const [scorecardModalOpen, setScorecardModalOpen] = useState(false);
    const [waitlistOpened, setWaitlistOpened] = React.useState(false);
    const [timerElapsed, setTimerElapsed] = React.useState(0);

    // Waitlist
    const [subscriptionSuccessSnackOpen, setSubscriptionSuccessSnackOpen] = React.useState(false);
    const [subscriptionFailSnackOpen, setSubscriptionFailSnackOpen] = React.useState(false);

    const handleOpenWaitlist = () => {
      setWaitlistIsOpen(true);
    }

    const handleCloseWaitlist = () => {
      setWaitlistIsOpen(false);
    }

    const handleCloseSuccessSnack = () => {
      setSubscriptionSuccessSnackOpen(false);
    }

    const handleCloseFailSnack = () => {
      setSubscriptionFailSnackOpen(false);
    }

    const validateEmail = (email) => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }    

    const handleJoinWaitlist = async () => {
      const emailValid = validateEmail(email)
      if(!emailValid) {
        setSubscriptionFailSnackOpen(true)
        return
      }

      try {
        const response = await axios.post(proxyEndpoint + '/join_waitlist', {
          subscription: { email: email }
        });
        if(!response.data.result) {
          setSubscriptionFailSnackOpen(true);  
        } else {
          handleCloseWaitlist();
          setSubscriptionSuccessSnackOpen(true);
          setWaitlistIsOpen(false);
        }
      } catch (error) {
        setSubscriptionFailSnackOpen(true);
      }
      
    }

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const handleCloseSubscriptionFailSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionFailSnackOpen(false);
    };

    const handleCloseSubscriptionSuccessSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSubscriptionSuccessSnackOpen(false);
    };

    // Tabs
    const [tabValue, setTabValue] = React.useState(0);

    // Mobile home screen mode
    const [startMobileDemo, setStartMobileDemo] = React.useState(false);

    const handleChangeTab = (event, newValue) => {
      setTabValue(newValue);
    };


    // Material UI responsive layout settings
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const excalidrawStyle = {
        minHeight: '60vh',
        width: '100%',
        background: '#050505', // Change the background colo
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // UI
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      fontFamily: 'figtree, sans-serif', // Set the font family to "figtree"
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginRight: '20px'
    }));

    // Timer
    const [timer, setTimer] = React.useState(interviewDuration);

    useEffect(() => {
      let interval = null;
      if (interviewStarted) {
        console.log('starting timer')
        interval = setTimeout(() => {
          if(timer > 0) {
            setTimer(timer => timer - 1)
            setTimerElapsed(timerElapsed => timerElapsed + 1)
          }
        }, 1000);
      }

      return () => {
          if (interval !== null && timer <= 0) {
              clearTimeout(interval);
          }
      };
  }, [interviewStarted, timer]);
    // Help button
    const handleHelpOpen = () => {
      setOpenHelp(true);
    }
  
    const handleHelpClose = () => {
      setOpenHelp(false);
    };

    const handleStartMobileDemo = () => {
      setStartMobileDemo(true);
    }

    if(excalidrawAPI !== null && !updated) {
        fetch('prefill.excalidraw.json')
        .then(response => response.json())
        .then(data => {
          excalidrawAPI.updateScene({elements: data.elements, appState: data.appState});
          excalidrawAPI.scrollToContent(data.elements, {
              fitToContent: true,
              animate: false,
          });
          setUpdated(true);
          const appState = excalidrawAPI.getAppState();
          const elements = excalidrawAPI.getSceneElements();
          if (!elements || !elements.length) {
              return
          }          

          exportToBlob({
              elements,
              appState: {
                  ...appState,
                  exportWithDarkMode: false,
              },
              files: excalidrawAPI.getFiles(),
              maxWidthOrHeight: 1000
          })
          .catch((error) => {
              console.error('Error:', error);
          });
        })
    }

    const initWebsocketConnection = (callId, interviewTypeParam) => {
      let clientType = 'desktop'
      if (isMobile) {
        clientType = 'mobile'
      }

      console.log('initWebsocketConnection')
      const socket = io(wsEndpoint, {
        withCredentials: true,
        transports: ["websocket"]        
      });
      socket.on('connect', () => {
        console.log('Websocket connected');
      });

      socket.on('disconnect', () => {
        console.log('Websocket disconnected');
      });

      console.log(globalInterviewType);

      socket.emit('register', {
        callId: callId,
        clientType: clientType,
        interviewType: interviewTypeParam,
        customer: 'instawork'
      });
      socket.on('update', (interview) => {
        console.log('update', interview.metadata.callId, callId)
        console.log(interview.scorecard)
        if(interview.metadata.callId === callId) {
          setInterview(interview);
        }
      });
      socket.on('parrot', (res) => {
        console.log('par', res.callId)
        if(res.callId === callId) {
          console.log('parroting')
          vapi.send({
            type: "add-message",
            message: {
              role: "system",
              content: "parrot_: " + res.res,
            },
          });        
        }
      })

      return socket;
    }

    // const handleStartInterview_Sindarin = () => {
    //   const script = document.createElement("script");
    //   const apiKey = "d935082f-b4d0-4c99-a496-c69bf1b5c249";      
    //   script.src = "https://api.sindarin.tech/PersonaClientPublic?apikey=" + apiKey;      
    //   const personaName = ""
    //   const userId = ""

    //   script.addEventListener("load", async () => {
    //     console.log("persona client loaded");
    //     const personaClient = new window.PersonaClient(apiKey);      
    //     personaClient.init(userId, personaName)      
        
    //   })
    // }

    const handleStartInterview = (interviewTypeParam) => {
        console.log('handleStartInterview')
        setInterviewStarted(true);
        var interviewType = 'system_design'
        if(interviewTypeParam !== '') {
          interviewType = interviewTypeParam
          globalInterviewType = interviewType
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const interviewTypeQueryParam = urlParams.get('interview_type');
          if (interviewTypeQueryParam) {
            interviewType = interviewTypeQueryParam;
            globalInterviewType = interviewType
          }
        }

        vapi.start(vapiEnvId)
        .then((data) => {
          // i assume ID is callId
          callId = data['id']
          if(socket == undefined) {
              socket = initWebsocketConnection(callId, interviewTypeParam);
          }                  
        })
        .catch((err) => {console.error(err)})

        vapi.on('speech-start', () => {
            console.log('Speech has started');
            setVisualState('speaking');
        });
        
        vapi.on('speech-end', () => {
            console.log('Speech has ended');
            setVisualState('listening');
        });
        
        vapi.on('call-start', () => {
            console.log('Call has started');            
        });
        
        vapi.on('call-end', () => {
            console.log('Call has stopped');
        });
        
        vapi.on('volume-level', (volume) => {
        });
        
        // Function calls and transcripts will be sent via messages
        vapi.on('message', (message) => {
            console.log(message);
        });
        
        vapi.on('error', (e) => {
            console.error(e)
        });   
    }

    const handleStopInterview = () => {
      console.log('handleStopInterview')
      if(!scorecardModalOpen) {
        vapi.stop()
        // get scorecard
        if(stopInvoked) {
          return 
        }
        stopInvoked = true
        console.log('making request')
        axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })                  
        .then((response) => {
            console.log(response.data)
        })
        .catch((error) => {          
            console.error('Error fetching scorecard:', error);
        });
  
        setScorecardModalOpen(true);        

        // if(socket != null) {
        //     socket.close();
        //     socket = null;
        // }
        setInterview(emptyInterviewObject)
      }
    }

    const handleSubmitDiagramFeedback = () => {
      console.log('handleSubmitDiagramFeedback')
      setLoadingDiagramFeedback(true);
      const buffering_reses = [
        'Hang on, taking a look at the whiteboard', 
        'One sec, taking a look', 
        'Looking, give me one second.',
        "Bear with me, I'm perusing the whiteboard details.",
        "Just a tick, I'm eyeing the whiteboard content.",
        'Allow me a brief pause to scan the whiteboard.',
        'I need a short moment to digest the whiteboard info.',
        'Let me take a swift glance at the whiteboard.',
        "I'm diving into the whiteboard, hold on.",
        'A quick study of the whiteboard is underway.',
        "I'm zooming in on the whiteboard, stand by.",
        'Peering into the whiteboard, just a moment.',
        "I'm deciphering the whiteboard scribbles, hang tight."
      ];
      const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];
      vapi.send({
        type: "add-message",
        message: {
          role: "system",
          content: "parrot_: " + buffering_res,
        },
      });        
      
      if (!excalidrawAPI) {
          return
      }
      const elements = excalidrawAPI.getSceneElements();
      if (!elements || !elements.length) {
          return
      }

      const appState = excalidrawAPI.getAppState();

      exportToBlob({
          elements,
          appState: {
              ...appState,
              exportWithDarkMode: false,
          },
          files: excalidrawAPI.getFiles(),
          maxWidthOrHeight: 1000
      })
      .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
              const base64data = reader.result;
              fetch(`${proxyEndpoint}/get_diagram_feedback`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ image: base64data, callId: callId })
              })
              .then((response) => {
                  setLoadingDiagramFeedback(false);
                  response.json()
                  .catch((err) => {console.error(err)})
              })
              .catch((err) => {console.error(err)})
          }
      }) 
    }

    if (timer <= 0) {
        handleStopInterview()
    }

    const renderContext = () => {
      return interview.context.map((message, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
            <Avatar sx={{ bgcolor: 'black', color: 'white', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
            <Typography variant="body2" sx={{ fontFamily: 'Mulish', color: '#C6C2D0', }}>{message}</Typography>
        </div>
      ))      
    }

    function secondsToHumanReadableTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
    
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    
      return formattedTime;
    }    

    const handleToggleMute = () => {
      console.log('handleToggleMute')
      socket.emit('mute', callId);
    }

    const enableMic = () => {
      navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function(stream) {
        setMicEnabled(true);
      })
      .catch(function(err) {
        console.log('Microphone permission denied');
      });
    }
    console.log('(isMobile && firstMobileScreenDone) || !isMobile', (isMobile && firstMobileScreenDone) || !isMobile)
    var interviewType = 'system_design'
    const urlParams = new URLSearchParams(window.location.search);
    const interviewTypeQueryParam = urlParams.get('interview_type');
    var interviewTypeModalText = ''
    if (interviewTypeQueryParam) {
      globalInterviewType = interviewTypeQueryParam;
    }
    if (globalInterviewType === 'virtual_assistant') {
      interviewTypeModalText = 'virtual assistant'
    } else if (globalInterviewType === 'system_design') {
      interviewTypeModalText = 'system design mock'
    } else if (globalInterviewType === 'instacart_biz_ops') {
      interviewTypeModalText = 'Instacart Business Operations pre-screening'
    } else if (globalInterviewType === 'residency') {
      interviewTypeModalText = 'medical residency mock'
    } else if (globalInterviewType === 'patient_questionnaire') {
      interviewTypeModalText = 'patient care' 
    }

    // if(!waitlistOpened && timerElapsed >= 2 * 60 ) {    
    //   setWaitlistOpened(true)
    //   setWaitlistIsOpen(true)
    //   handleToggleMute();
    // }

    return (
        <>
            <Container>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} sm={12}>
                  <form>
                    <Dialog open={waitlistIsOpen} onClose={() => {setWaitlistIsOpen(true)}}>
                      <DialogTitle
                        style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'
                        }}
                      >Waitlist</DialogTitle>
                      <DialogContent
                        style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          padding: 10,
                          fontFamily: 'Mulish'
                        }}
                      >
                        <DialogContentText style={{
                          fontFamily: 'Mulish',
                          color: 'white',
                          padding: 10
                        }}>
                          Join the waitlist to get access to your recording and the fully featured product!
                          <br/>
                          <br/>
                          <input
                            required
                            onChange={handleEmailChange}
                            style={{ 
                              color: 'white',
                              width: 300,
                              fontSize: 18,
                              fontFamily: 'Mulish',
                              background: 'black',
                              paddingBottom: 10,
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderBottom: '0.5px solid white',
                            }}
                            type="email"
                            placeholder={'Enter your email address'}
                          />   
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions 
                        style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'
                        }}
                      >
                        <Button 
                          type="submit"
                          style={{ color: 'black', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}                    
                          onClick={handleJoinWaitlist}>Join</Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                  <Snackbar
                    open={subscriptionSuccessSnackOpen}
                    autoHideDuration={5000}
                    onClose={handleCloseSubscriptionSuccessSnack}>
                      <Alert onClose={handleCloseSuccessSnack} severity="success" sx={{ width: '100%' }}>
                        You're on the waitlist!
                      </Alert>
                    </Snackbar>
                    <Snackbar
                    open={subscriptionFailSnackOpen}
                    autoHideDuration={5000}
                    onClose={handleCloseSubscriptionFailSnack}>
                      <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                        Error: e-mail already used or invalid format.
                      </Alert>
                    </Snackbar>
                </Grid>
              </Grid>
            </Container>
            {/* {((isMobile && firstMobileScreenDone) || !isMobile) &&
            <Paper elevation={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: 9999, background: '#181818', color: 'white' }}>
              <Typography variant="h6" style={{ cursor: 'pointer', textTransform: 'none' }}>
                <Button onClick={handleOpenWaitlist} style={{ color: 'white', fontWeight: 200, textTransform: 'none', fontSize: 16, fontFamily: 'Mulish' }}>
                  {isMobile &&
                    <>
                    Get future access.
                    </>  
                  }
                  {!isMobile &&
                    <>
                      
                      Get future access and your interview recording.
                    </>
                  }
                  <span onClick={handleOpenWaitlist} style={{ textDecoration: 'underline', marginLeft: '4px' }}>Join waitlist.</span>
                </Button>
              </Typography>
            </Paper>                        
            } */}
            <Container maxWidth="xl" style={{ backgroundColor: '#050505' }}>       
              <div style={{ paddingTop: 25, marginBottom: 25 }}>
                <a href="https://techinterviewer.ai">
                <img src="https://assets-global.website-files.com/63fd26f2fd0da53e0276079c/649b01c5530d496813d09908_instawork-white-logo.svg" alt="Instawork logo, white" class="nav-logo" />
                </a>
              </div>              
                <Grid container direction="column" spacing={3}>                  
                    <Grid item container xs={12} style={{ marginBottom: 15 }}>
                      {!isMobile &&
                      <Grid item xs={4} style={{ textAlign: 'left' }}>
                        <div style={{fontSize: 22, fontFamily: 'Mulish'}}>
                          {globalInterviewType === 'patient_questionnaire' &&
                            <>
                            Lupus Patient Check-in
                            </>
                          }
                          {globalInterviewType === 'system_design' &&
                            <>
                              System Design Interview: "Design a Blog App"
                            </>
                          }
                          {globalInterviewType === 'system_design_twitter' &&
                            <>
                              System Design Interview: "Design Twitter"
                            </>
                          }    
                          {globalInterviewType === 'system_design_instagram' &&
                            <>
                              System Design Interview: "Design Instagram"
                            </>
                          }                                              
                          {globalInterviewType === 'system_design_google_docs' &&
                            <>
                              System Design Interview: "Design Google Docs"
                            </>
                          }                              
                          {globalInterviewType === 'system_design_job_scheduler' &&
                            <>
                              System Design Interview: "Design a Job Scheduler"
                            </>
                          }                            
                          {globalInterviewType === 'virtual_assistant' &&
                            <>
                              Virtual Assistant Pre-Screening Interview
                            </>
                          }
                          {globalInterviewType === 'consulting' &&
                            <>
                              Consulting
                            </>
                          }
                          {globalInterviewType === 'market_sizing' &&
                            <>
                              Market Sizing
                            </>
                          }                        
                          {globalInterviewType === 'instacart_biz_ops' &&
                            <>
                              Instacart Business Operations Manager Pre-Screening Interview
                            </>
                          }     
                          {globalInterviewType === 'residency' &&
                            <>
                              Mock Residency Interview
                            </>
                          }                          
                          {globalInterviewType === 'phd_psych' &&
                            <>
                              Mock Clinical Psych PhD Interview
                            </>
                          }    
                          {globalInterviewType === 'therapy' &&
                            <>
                              Therapy Session
                            </>
                          }                        
                          {globalInterviewType === 'customer_support' &&
                            <>
                              Customer Support
                            </>
                          }                                    
                          {globalInterviewType === 'retail' &&
                            <>
                              Retail Sales Associate
                            </>
                          }                                                                            
                        </div>
                      </Grid>                    
                      }
                      {(firstMobileScreenDone && isMobile) &&
                      <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 30, marginTop: 30 }}>
                        <div style={{fontSize: 17, fontFamily: 'Mulish' }}>
                          {globalInterviewType === 'retail' &&
                            <>
                              Retail Sales Associate
                            </>
                          }                                                         
                          {globalInterviewType === 'customer_support' &&
                            <>
                              Customer Support
                            </>
                          }                                             
                          {globalInterviewType === 'system_design' &&
                            <>
                              Mock System Design Interview
                            </>
                          }
                          {globalInterviewType === 'system_design_twitter' &&
                            <>
                              System Design Interview: "Design Twitter"
                            </>
                          }    
                          {globalInterviewType === 'system_design_instagram' &&
                            <>
                              System Design Interview: "Design Instagram"
                            </>
                          }                                              
                          {globalInterviewType === 'system_design_google_docs' &&
                            <>
                              System Design Interview: "Design Google Docs"
                            </>
                          }                              
                          {globalInterviewType === 'system_design_job_scheduler' &&
                            <>
                              System Design Interview: "Design a Job Scheduler"
                            </>
                          }                                
                          {globalInterviewType === 'virtual_assistant' &&
                            <>
                              Virtual Assistant Pre-Screening Interview
                            </>
                          }
                          {globalInterviewType === 'instacart_biz_ops' &&
                            <>
                              Instacart Business Operations Manager Pre-Screening Interview
                            </>
                          }              
                          {globalInterviewType === 'residency' &&
                            <>
                              Mock Residency Interview
                            </>
                          }                                                     
                          {globalInterviewType === 'phd_psych' &&
                            <>
                              Mock Clinical Psych PhD Interview
                            </>
                          }    
                          {globalInterviewType === 'therapy' &&
                            <>
                              Therapy Session
                            </>
                          }  
                          {globalInterviewType === 'consulting' &&
                            <>
                              Consulting
                            </>
                          }        
                          {globalInterviewType === 'market_sizing' &&
                            <>
                              Market Sizing
                            </>
                          }                                        
                        </div>
                      </Grid>                         
                      }
                      <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!isMobile && 
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 30, fontSize: 16, fontFamily: 'Mulish', color:'#4451f6' }}>
                              {visualState === '' &&
                                <div style={{ color: interviewStarted ? 'white' : 'black' }}>
                                  Steve is Connecting...
                                </div>
                              }
                              {visualState === 'listening' &&
                                <div>
                                   Steve is <b>Listening</b>
                                </div>
                              }
                              {visualState === 'speaking' &&
                                <div>
                                   Steve is <b>Speaking</b>
                                </div>
                              }                          
                            </div>
                          }
                            {((isMobile && firstMobileScreenDone) || !isMobile) &&
                            <div style={{ display: 'flex', alignItems: isMobile ? 'left' : 'center', fontFamily: 'Mulish', fontSize: 18, color: 'white', marginRight: isMobile ? 0 : 15, position: isMobile ? 'absolute' : 'relative', left: isMobile ? '2.5vh' : 0 }}>
                              <AccessTimeIcon />
                              <span style={{ fontSize: 18, marginLeft: 5 }}>
                                {secondsToHumanReadableTime(timer)}
                              </span>
                            </div>                          
                            }
                            {!isMobile && globalInterviewType.startsWith('system_design') &&
                              <IconButton onClick={handleSubmitDiagramFeedback} disabled={loadingDiagramFeedback || !interviewStarted} style={{
                                background: '#181818',
                                color: 'white',
                                borderRadius: 25,
                                fontFamily: 'Mulish',
                                paddingLeft: 20, 
                                paddingRight: 20,
                                fontSize: 16,
                                marginLeft: 'auto', // Added to move the button to the right
                              }}>
                                {loadingDiagramFeedback ? 'Syncing...' : <><SyncIcon />
                                Sync</>}                          
                              </IconButton>
                            }
                            {interviewStarted &&
                              <IconButton onClick={handleStopInterview} style={{
                                background: '#4451f6',
                                color: 'white',
                                borderRadius: 25,
                                fontFamily: 'Mulish',
                                paddingLeft: 20, 
                                paddingRight: 20,
                                fontSize: 16,
                                marginLeft: 15, // Added to move the button to the right
                              }} disabled={!interviewStarted}>
                                <StopIcon />
                                Stop/Score            
                              </IconButton>                          
                            }
                          </div>
                        </div>     
                      </Grid>
                    </Grid>
                    {isMobile &&
                    <Grid item xs={12} sm={12} style={{ marginBottom: 10 }}>
                      <div style={{ textAlign: 'left', alignItems: 'left', fontSize: 16, fontFamily: 'Mulish' }}>
                        {visualState === '' &&
                          <div style={{ color: interviewStarted ? '#4451f6' : 'black' }}>
                            Steve is Connecting...
                          </div>
                        }
                        {visualState === 'listening' &&
                          <div>
                            👂 Steve is <b>Listening</b>
                          </div>
                        }
                        {visualState === 'speaking' &&
                          <div>
                            🗣️ Steve is <b>Speaking</b>
                          </div>
                        }                          
                      </div>  
                    </Grid>                      
                    }                  
                    {!isMobile &&
                    <Grid item container style={{ display: tabValue === 1 ? 'none' : 'flex' }} >
                      <>
                      <Grid item xs={globalInterviewType.startsWith('system_design') ? 3 : 12} style={{ marginTop: -30, paddingRight:10 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontFamily: 'Mulish', fontSize: 18, }}>Instructions</p>
                      </div>
                      <br/>
                      {renderContext()}
                      {loadingDiagramFeedback && (
                          <div style={{ color: 'white', fontFamily: 'Mulish', display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={20} style={{ marginRight: 10 }} /> Updating Instructions...
                          </div>
                        )
                      }                                        
                      </Grid>
                      {globalInterviewType.startsWith('system_design') &&
                        <Grid item xs={9}>
                          <div className="excalidraw-custom">
                            <Excalidraw 
                              theme="dark"
                              excalidrawAPI={(api)=> setExcalidrawAPI(api)}                       
                            />   
                          </div>
                        </Grid>
                      }
                      </>
                    </Grid>
                    }                     
                    {((!isMobile && !interviewStarted) || (isMobile && firstMobileScreenDone && !interviewStarted)) && 
                      <Dialog open={!interviewStarted}>
                        <DialogTitle style={{
                          background: '#181818',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish',
                        }}>{!micEnabled ? "Get Started" : "Start Interview"}</DialogTitle>
                        <DialogContent style={{
                          background: '#181818',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          padding: 10
                        }}>
                          <DialogContentText>
                            {!micEnabled &&
                              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                                You will be doing a {interviewTypeModalText} interview. Please enable your microphone to talk to Steve, your AI interviewer
                              </div>
                            }
                            {(globalInterviewType.startsWith('system_design') && micEnabled) &&
                              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                                Your microphone is enabled!
                                <br/>
                                <br/>
                                Watch <a style={{ color: 'white' }} href="https://www.youtube.com/embed/d2wknWa1VVg">this live video</a> instead if you're not in a place to talk.
                              <p style={{ fontSize: 24, fontFamily: 'Mulish', textAlign: 'center' }}>Pick a System Design Problem</p>
                              <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#f5f5f5' }} onClick={() => handleStartInterview('system_design')}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish' }}>
                                        Design a Blog App
                                      </p>
                                      <ArticleIcon style={{ fontSize: 40, color: 'black', backgroundColor: 'white', borderRadius: '50%', marginBottom: 1 }} />                                    
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#1DA1F2' }} onClick={() => {handleStartInterview('system_design_twitter')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Twitter
                                      </p>
                                      <TwitterIcon style={{ fontSize: 40, color: '#1DA1F2', backgroundColor: 'white', borderRadius: '50%', padding: 5, marginBottom: 1 }} />                                    
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#4285F4' }} onClick={() => {handleStartInterview('system_design_google_docs')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Google Docs
                                      </p>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <GoogleIcon style={{ fontSize: 40, color: 'white', marginRight: 8 }} />
                                        <ArticleIcon style={{ fontSize: 40, color: 'white' }} />
                                      </div>                                    
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#B8860B' }} onClick={() => handleStartInterview('system_design_job_scheduler')}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design a Job Scheduler
                                      </p>
                                      <ScheduleIcon style={{ fontSize: 40, color: 'white', marginRight: 8 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>  
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#C13584' }} onClick={() => {handleStartInterview('system_design_instagram')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Instagram
                                      </p>
                                      <InstagramIcon style={{ fontSize: 40, color: 'white', marginRight: 8 }} />
                                    </CardContent>
                                  </Card>
                                </Grid>                                                               
                                <Grid item>
                                  <Card sx={{ minHeight: 160, maxHeight: 160, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: '#1DB954' }} onClick={() => {handleStartInterview('system_design_spotify')}}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <p style={{ fontSize: 20, fontFamily: 'Mulish', color: 'white' }}>
                                        Design Spotify
                                      </p>
                                      <LibraryMusicIcon style={{ fontSize: 40, color: 'white', marginBottom: 1 }} />                                    
                                    </CardContent>
                                  </Card>
                                </Grid>                                                           
                              </Grid>
                              </div>
                            }
                            {(!globalInterviewType.startsWith('system_design') && micEnabled) &&
                              <div style={{ color: 'white', fontFamily: 'Mulish', padding: 15 }}>
                                Your microphone is enabled! Press the start interview button and our interviewer Steve will read you off a prompt and instructions.
                                <br/>
                                <br/>
                                Watch <a style={{ color: 'white' }} href="https://www.youtube.com/embed/d2wknWa1VVg">this live video</a> instead if you're not in a place to talk.
                              </div>                            
                            }
                          </DialogContentText>
                        </DialogContent>
                          <DialogActions style={{ background: 'black', border: '0.5px solid #5F6368', padding: 10 }}>
                              <Button autoFocus onClick={enableMic} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                                {micEnabled ? 'Microphone Enabled' : 'Enable Microphone'}
                              </Button>   
                              {(!globalInterviewType.startsWith('system_design') && micEnabled) &&
                                <Button onClick={() => {handleStartInterview(globalInterviewType)}} disabled={!micEnabled} style={{ color: 'white', background: '#4451f6', borderRadius: 25, fontFamily: 'Mulish' }}>
                                  Start Interview
                                </Button>                        
                              }                                                          
                          </DialogActions>
                      </Dialog>
                    }
                    {/* {timer <= 0 && 
                      <Dialog open={timer <= 0}>
                        <DialogTitle>Nice Work!</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Thank you so much for trying the experience. We will send you a recording of your experience and a scorecard to your email.
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            onChange={handleEmailChange}
                            variant="standard"
                          />                        
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleJoinWaitlist}>
                            Enter Email
                          </Button>
                        </DialogActions>
                      </Dialog>
                    } */}
                </Grid>              
            </Container>
            {scorecardModalOpen && (
              <Dialog
                style={{
                  background: 'black',
                  color: 'white',
                  border: '0.5px solid #5F6368',
                  fontFamily: 'Mulish'                  
                }}                          
                open={scorecardModalOpen}
                onClose={() => setScorecardModalOpen(false)}
                aria-labelledby="scorecard-dialog-title"
                aria-describedby="scorecard-dialog-description"
                fullWidth
                maxWidth="md" // Changed from default to make the dialog wider
              >
                <DialogTitle id="scorecard-dialog-title" 
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'                  
                  }}                            
                >Interview Scorecard</DialogTitle>
                <DialogContent
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'                  
                  }}              
                >
                  <DialogContentText id="scorecard-dialog-description">
                    Here's how you did in your interview.
                  </DialogContentText>
                  <List>
                    <TableContainer component={Paper} 
                      style={{
                        background: 'black',
                        color: 'white',
                        border: '0.5px solid #5F6368',
                        fontFamily: 'Mulish'                  
                      }}                                
                    >
                      <Table aria-label="scorecard table">
                        <TableHead style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                               
                        }}>
                          <TableRow style={{
                            background: 'black',
                            color: 'white',
                            border: '0.5px solid #5F6368',
                            fontFamily: 'Mulish'                                 
                          }}>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}}>Signal</TableCell>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Feedback</TableCell>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Pass?</TableCell>
                            <TableCell style={{ color: 'white', fontFamily: 'Mulish'}} align="left">Discussed in Interview</TableCell>                          
                          </TableRow>
                        </TableHead>
                        <TableBody style={{
                          background: 'black',
                          color: 'white',
                          border: '0.5px solid #5F6368',
                          fontFamily: 'Mulish'                               
                        }}>
                          {interview.scorecard && interview.scorecard.length === 0 &&
                          <TableRow style={{
                            background: 'black',
                            color: 'white',
                            border: '0.5px solid #5F6368',
                            fontFamily: 'Mulish',
                            height: '100%', // Ensure TableRow takes full height
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <TableCell colSpan={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <CircularProgress style={{ color: 'white' }} />
                            </TableCell>
                          </TableRow>
                          }
                          {interview.scorecard && interview.scorecard.length > 0 &&
                            <>
                              {interview.scorecard.map((row) => (
                                <TableRow key={row.signal} style={{
                                  background: 'black',
                                  color: 'white',
                                  border: '0.5px solid #5F6368',
                                  fontFamily: 'Mulish'                                       
                                }}>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} component="th" scope="row">
                                    {row.signal}
                                  </TableCell>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.feedback}</TableCell>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.asked_to_candidate ? row.score > 5 ? 'Yes' : 'No' : <>-</>}</TableCell>
                                  <TableCell style={{ color: 'white', fontFamily: 'Mulish' }} align="left">{row.asked_to_candidate ? "Yes" : "No"}</TableCell>                                 
                                </TableRow>
                              ))}    
                              {interview.scoring_pending &&
                                <TableCell style={{ borderBottom: 'none' }}>
                                  <CircularProgress style={{ color: 'white' }} />
                                </TableCell>                              
                              }                                                  
                            </>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </List>   
                  {/* <br/><br/>
                  <p style={{ color: 'white', fontFamily: 'Mulish', fontWeight: 600 }}>Join the waitlist to get your recording. </p>    
                  <TextField
                    InputLabelProps={{
                      style: { color: 'white', fontFamily: 'Mulish' },
                    }}
                    inputProps={{
                      style: { color: 'white', fontFamily: 'Mulish' },
                    }}    
                    InputProps={{
                      style: { color: 'white', borderBottom: '1px solid white' },
                    }}                          
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Enter your email"
                    type="email"
                    fullWidth
                    onChange={handleEmailChange}
                    variant="standard"
                  />                                */}
                </DialogContent>
                {/* <DialogActions
                  style={{
                    background: 'black',
                    color: 'white',
                    border: '0.5px solid #5F6368',
                    fontFamily: 'Mulish'                  
                  }}              
                >
                  <Button style={{ color: 'white', fontFamily: 'Mulish'}} onClick={handleJoinWaitlist}>
                    Submit
                  </Button>    
                </DialogActions>  */}
              </Dialog>
            )}
            {firstMobileScreenDone && isMobile &&
                <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto', border: '0.5px solid #5F6368', borderRadius: 10, display: 'flex', alignItems: 'center' }}>
                  <ChatIcon style={{ color: 'green', marginRight: '10px' }} />
                  <p style={{ fontFamily: 'Mulish', fontSize: 18 }}>Instructions</p>
                  <br/><br/><br/>
                  {renderContext()}                                     
                </Grid>
            }         
            {!firstMobileScreenDone && isMobile && 
              <Grid item container xs={12} sm={12} style={{ padding: 10, margin: '0 auto',borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ marginBottom: 80, border: '0.5px solid #5F6368', borderRadius: 10 }}>
                  <iframe width="290px" height="203px" src="https://www.youtube.com/embed/MzvYtFLs9Tc?si=hVH8J-pIEztrLzOh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                   
                </div>
                <button style={{ color: 'white', background: '#4451f6', border: '0 none', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={() => {setFirstMobileScreenDone(true)}}>
                  Start Interview
                </button>
                <br/>
                <br/>      
                <br/>
                <br/>      
                {/*<button style={{ color: 'white', background: 'black', border: '1px solid white', borderRadius: 25, fontFamily: 'Mulish', fontSize: 16, width: '80%', height: 48 }} onClick={handleOpenWaitlist}>
                  Join Waitlist
            </button> */}               
              </Grid>    
            }    
            <Container>
              <Snackbar
                open={subscriptionSuccessSnackOpen}
                autoHideDuration={5000}
                onClose={handleCloseSubscriptionSuccessSnack}>
                  <Alert onClose={handleCloseSuccessSnack} severity="success" sx={{ width: '100%' }}>
                    You're on the waitlist!
                  </Alert>
                </Snackbar>
              <Snackbar
              open={subscriptionFailSnackOpen}
              autoHideDuration={5000}
              onClose={handleCloseSubscriptionFailSnack}>
                <Alert onClose={handleCloseFailSnack} severity="error" sx={{ width: '100%' }}>
                  Error: e-mail already used or invalid format.
                </Alert>
              </Snackbar>
            </Container>
        </>
    );
};

export default App;